/* eslint-disable no-mixed-operators */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { MdPlayArrow } from "react-icons/md";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Rating } from "react-simple-star-rating";
import Baseurl from "../../Baseurl";
import axios from "axios";
import { useHistory } from "react-router-dom";

const CoSelect = () => {
  const [key, setKey] = useState("non-qm");
  const [loader, setLoader] = useState(false);    
  const [Company, setCompany] = useState([]);
  const [CompanySingle, setCompanySingle] = useState([]);
  const [CompanyBroker, setCompanyBroker] = useState([]);
  const [companyId, setCompanyId] = useState("");

  const getComapny = () => {
    setLoader(true);
    var config = {
      method: "get",
      url: `${Baseurl.baseurl}borrower/get/company/brokers`,
    };
    axios(config)
      .then(function (response) {
        if (response.data.status === true) {
          setLoader(false);
          setCompany(response.data.data);
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
      });
  };

  const getComapnyBroker = (id) => {
    setLoader(true);
    var config = {
      method: "get",
      url: `${Baseurl.baseurl}borrower/get/company/brokers`,
      params: {
        company_id: id,
      },
    };

    axios(config)
      .then(function (response) {
        if (response.data.status === true) {
          setLoader(false);
          setCompanyBroker(response.data.data);
          console.log(response.data.data, "response.data.data");
        } else {
          setLoader(false);
        }
      })
      .catch(function (error) {
        setLoader(false);
      });
  };

  useEffect(() => {
    getComapny();
  }, []);

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;
    const selectedBrokerId = selectedValue.split(",")[0];
    const selectedBroker = Company.find(
      (broker) => broker.id === parseInt(selectedBrokerId)
    );
    setCompanyId(selectedBrokerId);
    getComapnyBroker(selectedBrokerId);
    if (selectedBroker) {
      setCompanySingle(selectedBroker);
    }
  };

  const  [BorrowerType,setBorrowerType] = useState("")
  const  [IncomeType,setIncomeType] = useState("")

  const  [BorrowerTypeSecondLien,setBorrowerTypeSecondLien] = useState("")
  const  [IncomeTypeSecondLien,setIncomeTypeSecondLien] = useState("")

  const  [BorrowerTypeGovernment,setBorrowerTypeGovernment] = useState("")
  const  [IncomeTypeGovernment,setIncomeTypeGovernment] = useState("")

  const  [BorrowerConventional,setBorrowerConventional] = useState("")
  const  [IncomeConventional,setIncomeConventional] = useState("")

  const  [BorrowerNon,setBorrowerNon] = useState("")
  const  [IncomeNon,setIncomeNon] = useState("")

const navigation = useHistory()
  const handlegoDaynamicPage = (page)=>{
    navigation.push(`/Borrower/Signup/CoSelect/${page}`)
  }


  return (
    <>
      <>{loader ? <div className="loader"></div> : null}</>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6 d-flex align-items-center">
            <p className="mt-3 position-relative w-100">
              {" "}
              <small>
                Company
                <select
                  className="form-select"
                  onChange={handleSelectChange}
                  aria-label="Default select example"
                >
                  <option value="" selected hidden>
                    Select Company
                  </option>
                  {Company?.filter((broker) => broker.first_name !== null).map(
                    (broker) => (
                      <option
                        key={broker.id}
                        value={`${broker.id},${broker.first_name}`}
                        onClick={() => setCompanySingle(broker)}
                      >
                        {broker.first_name} {broker.last_name}
                      </option>
                    )
                  )}
                </select>
                {companyId === "" ? null : (
                  <p
                    style={{
                      fontSize: "16px",
                      color: "green",
                      fontWeight: "bold",
                      position: "absolute",
                      right: "30px",
                      top: "30px",
                      zIndex: "123",
                    }}
                  >
                    ✔
                  </p>
                )}
              </small>
            </p>
            <Link to={"#"} className="btn btn-primary w-auto ms-3 mt-4">
              Next&nbsp;
            </Link>
          </div>
        </div>
      </div>
      <div>
        <div className="container-fluid mt-4">
          {CompanyBroker?.length > 0 ? (
            <div className="row">
              <div className="col-md-2">
                <div className="card border-0">
                  <img
                    src={
                      CompanySingle?.image
                        ? Baseurl?.imgurl +
                          CompanySingle?.image_path +
                          "/" +
                          CompanySingle?.image
                        : "https://bankerbrokerreact.dev-oa.xyz/static/media/userlogo.74c8bc991df51e55915e.jpg"
                    }
                    className="card-img-top"
                    alt="Business"
                  />
                  <div className="card-body">
                    <h6
                      className="card-title text-black fw-medium"
                      style={{ fontWeight: 600 }}
                    >
                      Business Card Link
                    </h6>
                    <h6
                      className="card-title text-black fw-medium"
                      style={{ fontWeight: 600 }}
                    >
                      Resume Link
                    </h6>
                    <h6
                      className="card-title text-black fw-medium"
                      style={{ fontWeight: 600 }}
                    >
                      Website Link
                    </h6>
                    <h6
                      className="card-title text-black fw-medium"
                      style={{ fontWeight: 600 }}
                    >
                      Social Media Links
                    </h6>
                  </div>
                </div>
              </div>
              <div className="col-md-2">
                <div
                  className="card rounded-0"
                  style={{ border: "1px solid black" }}
                >
                  <img
                    src={
                      CompanySingle?.image
                        ? Baseurl?.imgurl +
                          CompanySingle?.image_path +
                          "/" +
                          CompanySingle?.image
                        : "https://bankerbrokerreact.dev-oa.xyz/static/media/Bankerlogo.253b09c45febbb7fee9b.png"
                    }
                    className="card-img-top"
                    alt="Business"
                  />

                  <div className="card-body px-2">
                    <h6
                      className="card-title text-black fw-medium"
                      style={{ fontWeight: 600 }}
                    >
                      {CompanySingle?.first_name
                        ? CompanySingle?.first_name
                        : CompanySingle?.first_name + CompanySingle?.last_name
                        ? CompanySingle?.first_name +
                          " " +
                          CompanySingle?.last_name
                        : "Massey Kouhssari"}
                    </h6>
                    <p className="card-title text-primary fw-medium mb-0">
                      BankerBroker MLO
                    </p>
                    <p className="card-title text-black fw-medium mb-0">
                      CADRE Broker Lic 01872368
                    </p>
                    <p className="card-title text-black fw-medium mb-2">
                      CANMLS DOC Lic 288498
                    </p>
                    <h6
                      className="card-title text-black fw-medium"
                      style={{ fontWeight: 600 }}
                    >
                      BankerBroker Real Estate & Mortgage
                    </h6>
                    <p className="card-title text-black fw-medium mb-0">
                      1 Corporate Plaza Dr.
                    </p>
                    <p className="card-title text-black fw-medium mb-2">
                      Newport Beach CA 92660
                    </p>
                    {/*  */}
                    <p className="card-title text-black fw-medium mb-0">
                      Toll Free: 877.410.6663
                    </p>
                    <p className="card-title text-black fw-medium mb-0">
                      Office: 949.607.8808
                    </p>
                    <p className="card-title text-black fw-medium mb-0">
                      Cell: 949.244.1880
                    </p>
                    <p className="card-title text-black fw-medium mb-0">
                      Fax: 949.966.0390
                    </p>
                    <p className="card-title text-black fw-medium mb-0">
                      {CompanySingle?.email}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>Team</th>
                      <th>Lender</th>
                      <th>Rate</th>
                      <th>Type</th>
                      <th>APR</th>
                      <th>Mo. payment</th>
                    </tr>
                  </thead>
                  <tbody>
                    {CompanyBroker?.map((company_id) => (
                      <>
                        <tr style={{ cursor: "pointer" }}>
                          <td>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                              <div>
                                <img
                                  src={
                                    company_id?.user?.image
                                      ? Baseurl?.imgurl +
                                        company_id?.user?.image_path +
                                        "/" +
                                        company_id?.user?.image
                                      : "https://bankerbrokerreact.dev-oa.xyz/static/media/Bankerlogo.253b09c45febbb7fee9b.png"
                                  }
                                  className="card-img-top"
                                  alt="Business"
                                  style={{
                                    width: "80px",
                                    height: "90px",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                              <div className="d-flex">
                                <Rating initialValue={5} size={24} />
                                <h6
                                  className="card-title text-black fw-medium"
                                  style={{ fontWeight: 600 }}
                                >
                                  4.8
                                </h6>
                                <p className="card-title text-primary fw-medium mb-0">
                                  (321)
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex flex-column justify-content-center align-items-center">
                              <div>
                                <img
                                  src={
                                    company_id?.user?.image
                                      ? Baseurl?.imgurl +
                                        company_id?.user?.image_path +
                                        "/" +
                                        company_id?.user?.image
                                      : "https://bankerbrokerreact.dev-oa.xyz/static/media/Bankerlogo.253b09c45febbb7fee9b.png"
                                  }
                                  className="card-img-top"
                                  alt="Business"
                                  style={{
                                    width: "80px",
                                    height: "80px",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                              <div>
                                <p className="card-title text-black fw-medium mb-0">
                                  NMLS Number: 123456789
                                </p>
                              </div>
                              <div className="d-flex">
                                <Rating initialValue={5} size={24} />
                                <h6
                                  className="card-title text-black fw-medium"
                                  style={{ fontWeight: 600 }}
                                >
                                  4.8
                                </h6>
                                <p className="card-title text-primary fw-medium mb-0">
                                  (321)
                                </p>
                              </div>
                              <div>
                                <p className="card-title text-black fw-medium mb-0">
                                  NMLS Number: 123456789
                                </p>
                              </div>
                            </div>
                          </td>
                          <td>
                            <h6
                              className="card-title text-black fw-medium"
                              style={{ fontWeight: 600 }}
                            >
                              5.870%
                            </h6>
                          </td>
                          <td>VA</td>
                          <td>6.036%</td>
                          <td>$2,271</td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : null}
          <div className="container">
            <div className="row d-flex justify-content-center mt-5">
              <div className="col-md-6">
                <ul>
                  <li style={{ listStyleType: "disc" }} className="text-black">
                    Custom rates in as little as 3 minutes with no impact to
                    your credit score
                  </li>
                  <li style={{ listStyleType: "disc" }} className="text-black">
                    Best Mortgage Lender for Affordability 2023 (Wall Street
                    Journal)
                  </li>
                  <li style={{ listStyleType: "disc" }} className="text-black">
                    Loan types to meet your needs - Jumbo, Conventional, FHA, VA
                  </li>
                  <li style={{ listStyleType: "disc" }} className="text-black">
                    USDA Loans
                  </li>
                  <li style={{ listStyleType: "disc" }} className="text-black">
                    Conventional & SBA Commercial Loans
                  </li>
                  <li style={{ listStyleType: "disc" }} className="text-black">
                    No Money Down to Luxury mortgages above 30 million dollars
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="mt-5 container turnaround">
            <div className="row mt-4 d-flex justify-content-center align-items-center">
              <div className="col-md-2">
                <div>
                  <h4 className="text-black">Disclosure</h4>
                  <h6 className="text-black">24 hours</h6>
                </div>
              </div>
              <div className="col-md-1">
                <MdPlayArrow
                  size={24}
                  style={{ color: "#0d6efd" }}
                  className="me-2"
                />
              </div>
              <div className="col-md-2">
                <div>
                  <h4 className="text-black">Underwriting</h4>
                  <h6 className="text-black">24 hours</h6>
                </div>
              </div>
              <div className="col-md-1">
                <MdPlayArrow
                  size={24}
                  style={{ color: "#0d6efd" }}
                  className="me-2"
                />
              </div>
              <div className="col-md-2">
                <div>
                  <h4 className="text-black">Conditions</h4>
                  <h6 className="text-black">24 hours</h6>
                </div>
              </div>
              <div className="col-md-1">
                <MdPlayArrow
                  size={24}
                  style={{ color: "#0d6efd" }}
                  className="me-2"
                />
              </div>
              <div className="col-md-2">
                <div>
                  <h4 className="text-black">Closing</h4>
                  <h6 className="text-black">24 hours</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 my-5">
            <div className="container">
              <h2 className="font-bold text-black">Programs</h2>
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3 d-flex justify-content-between w-100"
              >

<Tab eventKey="non-qm" title="Non-QM">

<div className="container" style={{ padding:'10px'}}>
<select style={{width:'240px',padding:"10px",borderRadius:'5px'}} onClick={(e)=>setBorrowerNon(e.target.value)}>
                                    <option hidden  value="program-block">Borrower type</option>
                                    <option value="employed">Employed</option>
                                    <option value="self-employed">Self-employed</option>
                                    <option value="investors">Investors</option>
                                    <option value="foreign-investors">Foreign Investors</option>
                                    <option value="high-net-worth-individuals">High Net Worth Individuals</option>
</select>

<select style={{width:'240px',padding:"10px",marginLeft:'10px',borderRadius:'5px'}} onClick={(e)=>setIncomeNon(e.target.value)}>
  <option hidden >Income type</option>
  <option value="2Y Full Doc">2Y Full Doc</option>
  <option value="1Y Full Doc">1Y Full Doc</option>
  <option value="24 Month Bank Statement">24 Month Bank Statement</option>
  <option value="12 Bank Statement">12 Bank Statement</option>
  <option value="TY P&L Only">TY P&L Only</option>
  <option value="2Y P&L Only">2Y P&L Only</option>
  <option value="Asset Utilization">Asset Utilization</option>
  <option value="WVOE">WVOE</option>
  <option value="1099">1099</option>
  <option value="DSCR >=1.00">DSCR &gt;=1.00</option>
  <option value="DSCR 0.75-0.99">DSCR 0.75-0.99</option>
  <option value="DSCR < 0.75">DSCR &lt; 0.75</option>
  <option value="Foreign income" >Foreign income</option>

</select>
</div>

{
BorrowerNon == "" && ( IncomeNon == "" || IncomeNon == "2Y Full Doc" || IncomeNon == "1Y Full Doc" ) ?
                  <div className="row" >

                    <div className="col-md-4">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselect1.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                              DSCR
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                              Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                              Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                            Close DSCR loan with the fastest turn times in the
                            industry
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Credit for DSCR 1.25
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              No income no employment
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Short-term rental is available
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('DSCR')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselect2.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                              12,24 Month Bank Statement
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                              Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                              Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                            A perfect fit for self-employed borrowers
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Personal, business, or combined bank statement
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              3 months of reserves
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Concierge Service for income calculation
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('12,24 Month Bank Statement')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselect10.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                              ITIN
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                              Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                              Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                            Expand your mortgage portfolio for borrowers without
                            an SSN
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Min FICO 660 and up to 80% CLTV for Super Prime
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Min FICO 700 and up to 70% CLTV for DSCR
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Loan amounts up to $1.5 million
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('ITIN')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 new">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselectnew4.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                            1Y + 2Y P&L                                                
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                            Min. FICO 660
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                            Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                          The easiest alternative solution for self-employed borrowers
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              P&L reviewed by Licensed CPA, CTEC registered Preparer or IRS Enrolled Tax Agent
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Bank statements not required up to 70% LTV
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Loan amounts up to $2.5 million
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('1Y + 2Y P&L')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mt-2 new">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselectnew5.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                            1Y or 2Y Full Doc Non-QM                                                                                                
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                            Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                            Up to 90% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                          A flexible Non-QM solution for Full Doc borrowers 
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Loan amounts up to $4 million
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              DTI up to 55% on owner-occupied 
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Min. 3 months of reserves
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('1Y or 2Y Full Doc Non-QM')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mt-2 new">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselectnew6.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                            1099                                                 
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                            Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                            Up to 85% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                          An ideal loan for sole proprietors or contract individuals 
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                             1099s for the last 1 year
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              1-year history with the same employer 
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Gift funds allowed 
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('1099')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mt-2 new">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselectnew7.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                            WVOE                                                
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                            Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                            Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                          A loan solution for salaried borrowers with no tax returns 
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                             Bank statements not required up to 70% LTV
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Min. 3 months of reserves
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              No tax returns required 
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('WVOE')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mt-2 new">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselectnew8.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                            Asset Utilization                                                
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                            Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                            Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                          An ideal solution for borrowers with liquid assets: retirees, investors, and the self-employed  
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                             Qualifying assets / 60 months
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                             Min. 3 months of reserves
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Loan amounts up to $4 million 
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('Asset Utilization')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mt-2 new">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselectnew9.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                            Foreign Nationals DSCR                                               
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                            No score or FICO 660
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                            Up to 70% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                          Qualify Foreign Nationals seeking investment properties with minimal documentation and fast closings
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                            Loan amounts up to $3 million
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                             Cash-out allowed
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              DSCR 1 requires min FICO 680 or no FICO
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('Foreign Nationals DSCR')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mt-2 new">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselectnew10.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                            Foreign Nationals Full Doc                                               
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                            No score or FICO 660
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                            Up to 75% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                          Qualify Foreign National borrowers with overseas income 
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                            Loan amounts up to $3 million
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                             Overseas assets allowed
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              RON allowed
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('Foreign Nationals Full Doc')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div> :  

BorrowerNon == "employed" && ( IncomeNon == "" || IncomeNon == "WVOE" ) ? 
<div className="col-md-4 mt-2 new">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselectnew7.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                            WVOE                                                
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                            Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                            Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                          A loan solution for salaried borrowers with no tax returns 
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                             Bank statements not required up to 70% LTV
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Min. 3 months of reserves
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              No tax returns required 
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('WVOE')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div> :
BorrowerNon == "self-employed" && ( IncomeNon == "" || IncomeNon == "1Y Full Doc" ||IncomeNon == "2Y Full Doc" ) ? 
<div className="col-md-4 mt-2 new">
<div
  className="card shadow-sm"
  style={{ width: "100%", borderRadius: "10px" }}
>
  <div className="card-body">
    <div className="d-flex">
      <div
        className="program-block__image program-block__image_dscr"
        style={{
          backgroundImage:
            "url('/img/coselect/coselectnew5.svg')",
        }}
      ></div>
      <h5 className="card-title text-start mt-3 ps-4 text-black">
      1Y or 2Y Full Doc Non-QM                                                                                                
      </h5>
    </div>
    <div className="d-flex justify-content-between mt-2">
      <span className="badge bg-light text-dark p-3 border">
      Min. FICO 620
      </span>
      <span className="badge bg-light text-dark p-3 border">
      Up to 90% CLTV
      </span>
    </div>
    <h6 className="card-text mt-3 text-start text-black">
    A flexible Non-QM solution for Full Doc borrowers 
    </h6>
    <ul className="list-unstyled mt-3">
      <li className="list text-black">
        <MdPlayArrow
          size={20}
          style={{ color: "#0d6efd" }}
          className="me-2"
        />
        Loan amounts up to $4 million
      </li>
      <li className="list text-black">
        {" "}
        <MdPlayArrow
          size={20}
          style={{ color: "#0d6efd" }}
          className="me-2"
        />
        DTI up to 55% on owner-occupied 
      </li>
      <li className="list text-black">
        <MdPlayArrow
          size={20}
          style={{ color: "#0d6efd" }}
          className="me-2"
        />
        Min. 3 months of reserves
      </li>
    </ul>
    <div className="d-flex justify-content-start mt-4">
      <button className="learn_more" onClick={()=>handlegoDaynamicPage('1Y or 2Y Full Doc Non-QM ')}>Learn More</button>
    </div>
  </div>
</div>
</div>  : 
BorrowerNon == "self-employed" && ( IncomeNon == "" || IncomeNon == "24 Month Bank Statement" ||IncomeNon == "12 Bank Statement" ) ?
 <div className="col-md-4">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselect2.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                              12,24 Month Bank Statement
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                              Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                              Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                            A perfect fit for self-employed borrowers
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Personal, business, or combined bank statement
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              3 months of reserves
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Concierge Service for income calculation
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('12,24 Month Bank Statement')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div> : 
BorrowerNon == "self-employed" && ( IncomeNon == "" || IncomeNon == "2Y P&L Only" ||IncomeNon == "TY P&L Only" ) 
                 ? 
                  <div className="col-md-4 new">
                 <div
                   className="card shadow-sm"
                   style={{ width: "100%", borderRadius: "10px" }}
                 >
                   <div className="card-body">
                     <div className="d-flex">
                       <div
                         className="program-block__image program-block__image_dscr"
                         style={{
                           backgroundImage:
                             "url('/img/coselect/coselectnew4.svg')",
                         }}
                       ></div>
                       <h5 className="card-title text-start mt-3 ps-4 text-black">
                       1Y + 2Y P&L                                                
                       </h5>
                     </div>
                     <div className="d-flex justify-content-between mt-2">
                       <span className="badge bg-light text-dark p-3 border">
                       Min. FICO 660
                       </span>
                       <span className="badge bg-light text-dark p-3 border">
                       Up to 80% CLTV
                       </span>
                     </div>
                     <h6 className="card-text mt-3 text-start text-black">
                     The easiest alternative solution for self-employed borrowers
                     </h6>
                     <ul className="list-unstyled mt-3">
                       <li className="list text-black">
                         <MdPlayArrow
                           size={20}
                           style={{ color: "#0d6efd" }}
                           className="me-2"
                         />
                         P&L reviewed by Licensed CPA, CTEC registered Preparer or IRS Enrolled Tax Agent
                       </li>
                       <li className="list text-black">
                         {" "}
                         <MdPlayArrow
                           size={20}
                           style={{ color: "#0d6efd" }}
                           className="me-2"
                         />
                         Bank statements not required up to 70% LTV
                       </li>
                       <li className="list text-black">
                         <MdPlayArrow
                           size={20}
                           style={{ color: "#0d6efd" }}
                           className="me-2"
                         />
                         Loan amounts up to $2.5 million
                       </li>
                     </ul>
                     <div className="d-flex justify-content-start mt-4">
                       <button className="learn_more" onClick={()=>handlegoDaynamicPage('1Y + 2Y P&L')}>Learn More</button>
                     </div>
                   </div>
                 </div>
               </div>  : 
BorrowerNon == "self-employed" && ( IncomeNon == "Asset Utilization" ) ?
<div className="col-md-4 mt-2 new">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselectnew8.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                            Asset Utilization                                                
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                            Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                            Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                          An ideal solution for borrowers with liquid assets: retirees, investors, and the self-employed  
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                             Qualifying assets / 60 months
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                             Min. 3 months of reserves
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Loan amounts up to $4 million 
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('Asset Utilization')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div> :
BorrowerNon == "self-employed" && ( IncomeNon == "1099")  ?
<div className="col-md-4 mt-2 new">
<div
  className="card shadow-sm"
  style={{ width: "100%", borderRadius: "10px" }}
>
  <div className="card-body">
    <div className="d-flex">
      <div
        className="program-block__image program-block__image_dscr"
        style={{
          backgroundImage:
            "url('/img/coselect/coselectnew6.svg')",
        }}
      ></div>
      <h5 className="card-title text-start mt-3 ps-4 text-black">
      1099                                                 
      </h5>
    </div>
    <div className="d-flex justify-content-between mt-2">
      <span className="badge bg-light text-dark p-3 border">
      Min. FICO 620
      </span>
      <span className="badge bg-light text-dark p-3 border">
      Up to 85% CLTV
      </span>
    </div>
    <h6 className="card-text mt-3 text-start text-black">
    An ideal loan for sole proprietors or contract individuals 
    </h6>
    <ul className="list-unstyled mt-3">
      <li className="list text-black">
        <MdPlayArrow
          size={20}
          style={{ color: "#0d6efd" }}
          className="me-2"
        />
       1099s for the last 1 year
      </li>
      <li className="list text-black">
        {" "}
        <MdPlayArrow
          size={20}
          style={{ color: "#0d6efd" }}
          className="me-2"
        />
        1-year history with the same employer 
      </li>
      <li className="list text-black">
        <MdPlayArrow
          size={20}
          style={{ color: "#0d6efd" }}
          className="me-2"
        />
        Gift funds allowed 
      </li>
    </ul>
    <div className="d-flex justify-content-start mt-4">
      <button className="learn_more" onClick={()=>handlegoDaynamicPage('1099')}>Learn More</button>
    </div>
  </div>
</div>
</div> :
BorrowerNon == "investors" && (  IncomeNon == "" || IncomeNon == "1Y Full Doc" ||IncomeNon == "2Y Full Doc" )  ?
<div className="row">
<div className="col-md-4">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselect10.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                              ITIN
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                              Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                              Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                            Expand your mortgage portfolio for borrowers without
                            an SSN
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Min FICO 660 and up to 80% CLTV for Super Prime
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Min FICO 700 and up to 70% CLTV for DSCR
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Loan amounts up to $1.5 million
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('ITIN')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div>
  <div className="col-md-4 mt-2 new">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselectnew5.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                            1Y or 2Y Full Doc Non-QM                                                                                                
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                            Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                            Up to 90% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                          A flexible Non-QM solution for Full Doc borrowers 
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Loan amounts up to $4 million
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              DTI up to 55% on owner-occupied 
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Min. 3 months of reserves
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('1Y or 2Y Full Doc Non-QM')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div>
</div> :

BorrowerNon == "investors" || BorrowerNon == "foreign-investors" && (  IncomeNon == "" || IncomeNon == "1Y Full Doc" ||IncomeNon == "2Y Full Doc" )  ?
<div className="row">
<div className="col-md-4">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselect2.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                              12,24 Month Bank Statement
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                              Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                              Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                            A perfect fit for self-employed borrowers
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Personal, business, or combined bank statement
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              3 months of reserves
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Concierge Service for income calculation
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('12,24 Month Bank Statement')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselect10.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                              ITIN
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                              Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                              Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                            Expand your mortgage portfolio for borrowers without
                            an SSN
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Min FICO 660 and up to 80% CLTV for Super Prime
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Min FICO 700 and up to 70% CLTV for DSCR
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Loan amounts up to $1.5 million
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('ITIN')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div>

</div>
: 
BorrowerNon == "investors" || BorrowerNon == "foreign-investors" && (  IncomeNon == "" || IncomeNon == "12 Bank Statement" ||IncomeNon == "24 Month Bank Statement" )  ? 
<div className="row">
<div className="col-md-4">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselect10.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                              ITIN
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                              Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                              Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                            Expand your mortgage portfolio for borrowers without
                            an SSN
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Min FICO 660 and up to 80% CLTV for Super Prime
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Min FICO 700 and up to 70% CLTV for DSCR
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Loan amounts up to $1.5 million
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('ITIN')}>Learn More</button>
                          </div>
                        </div>
                      </div>
</div>
                    <div className="col-md-4">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselect2.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                              12,24 Month Bank Statement
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                              Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                              Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                            A perfect fit for self-employed borrowers
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Personal, business, or combined bank statement
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              3 months of reserves
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Concierge Service for income calculation
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('12,24 Month Bank Statement')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div>
</div> : 
BorrowerNon == "investors" || BorrowerNon == "foreign-investors" && (  IncomeNon == "" || IncomeNon == "TY P&L Only" ||IncomeNon == "2Y P&L Only" )  ? 
<div className="row">
<div className="col-md-4">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselect10.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                              ITIN
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                              Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                              Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                            Expand your mortgage portfolio for borrowers without
                            an SSN
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Min FICO 660 and up to 80% CLTV for Super Prime
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Min FICO 700 and up to 70% CLTV for DSCR
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Loan amounts up to $1.5 million
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('ITIN')}>Learn More</button>
                          </div>
                        </div>
                      </div>
</div>
<div className="col-md-4 new">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselectnew4.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                            1Y + 2Y P&L                                                
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                            Min. FICO 660
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                            Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                          The easiest alternative solution for self-employed borrowers
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              P&L reviewed by Licensed CPA, CTEC registered Preparer or IRS Enrolled Tax Agent
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Bank statements not required up to 70% LTV
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Loan amounts up to $2.5 million
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('1Y + 2Y P&L')}>Learn More</button>
                          </div>
                        </div>
                      </div>
    </div>
</div> :
BorrowerNon == "investors" || BorrowerNon == "foreign-investors" && (  IncomeNon == "" || IncomeNon == "Asset Utilization" )  ? 
<div className="row" >
<div className="col-md-4">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselect10.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                              ITIN
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                              Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                              Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                            Expand your mortgage portfolio for borrowers without
                            an SSN
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Min FICO 660 and up to 80% CLTV for Super Prime
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Min FICO 700 and up to 70% CLTV for DSCR
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Loan amounts up to $1.5 million
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('ITIN')}>Learn More</button>
                          </div>
                        </div>
                      </div>
</div>
<div className="col-md-4 mt-2 new">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselectnew8.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                            Asset Utilization                                                
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                            Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                            Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                          An ideal solution for borrowers with liquid assets: retirees, investors, and the self-employed  
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                             Qualifying assets / 60 months
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                             Min. 3 months of reserves
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Loan amounts up to $4 million 
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('Asset Utilization')}>Learn More</button>
                          </div>
                        </div>
                      </div>
</div>
</div> : 
BorrowerNon == "investors" || BorrowerNon == "foreign-investors" && (  IncomeNon == "" || IncomeNon == "WVOE" )  ? 
<div className="row" >
<div className="col-md-4">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselect10.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                              ITIN
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                              Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                              Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                            Expand your mortgage portfolio for borrowers without
                            an SSN
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Min FICO 660 and up to 80% CLTV for Super Prime
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Min FICO 700 and up to 70% CLTV for DSCR
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Loan amounts up to $1.5 million
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('ITIN')}>Learn More</button>
                          </div>
                        </div>
                      </div>
</div>
 <div className="col-md-4 mt-2 new">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselectnew7.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                            WVOE                                                
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                            Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                            Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                          A loan solution for salaried borrowers with no tax returns 
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                             Bank statements not required up to 70% LTV
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Min. 3 months of reserves
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              No tax returns required 
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('WVOE')}>Learn More</button>
                          </div>
                        </div>
                      </div>
 </div>
</div> : 
BorrowerNon == "investors" || BorrowerNon == "foreign-investors" && (  IncomeNon == "" || IncomeNon == "1099" )  ? 
<div className="row" >
<div className="col-md-4">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselect10.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                              ITIN
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                              Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                              Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                            Expand your mortgage portfolio for borrowers without
                            an SSN
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Min FICO 660 and up to 80% CLTV for Super Prime
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Min FICO 700 and up to 70% CLTV for DSCR
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Loan amounts up to $1.5 million
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('ITIN')}>Learn More</button>
                          </div>
                        </div>
                      </div>
</div>
<div className="col-md-4 mt-2 new">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselectnew6.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                            1099                                                 
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                            Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                            Up to 85% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                          An ideal loan for sole proprietors or contract individuals 
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                             1099s for the last 1 year
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              1-year history with the same employer 
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Gift funds allowed 
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('1099')}>Learn More</button>
                          </div>
                        </div>
                      </div>
</div>
</div> : 
BorrowerNon == "investors" || BorrowerNon == "foreign-investors" &&  (  IncomeNon == "" || IncomeNon == "DSCR < 0.75" || IncomeNon == "DSCR >=1.00" || IncomeNon == "DSCR 0.75-0.99" )  ? 
<div className="row" >
<div className="col-md-4">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselect10.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                              ITIN
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                              Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                              Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                            Expand your mortgage portfolio for borrowers without
                            an SSN
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Min FICO 660 and up to 80% CLTV for Super Prime
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Min FICO 700 and up to 70% CLTV for DSCR
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Loan amounts up to $1.5 million
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('ITIN')}>Learn More</button>
                          </div>
                        </div>
                      </div>
</div>
<div className="col-md-4">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselect1.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                              DSCR
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                              Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                              Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                            Close DSCR loan with the fastest turn times in the
                            industry
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Credit for DSCR 1.25
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              No income no employment
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Short-term rental is available
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('DSCR')}>Learn More</button>
                          </div>
                        </div>
                      </div>
</div>
<div className="col-md-4 mt-2 new">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselectnew9.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                            Foreign Nationals DSCR                                               
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                            No score or FICO 660
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                            Up to 70% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                          Qualify Foreign Nationals seeking investment properties with minimal documentation and fast closings
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                            Loan amounts up to $3 million
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                             Cash-out allowed
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              DSCR 1 requires min FICO 680 or no FICO
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('Foreign Nationals DSCR')}>Learn More</button>
                          </div>
                        </div>
                      </div>
</div>

</div> : 
BorrowerNon == "investors" || BorrowerNon == "foreign-investors"  && (  IncomeNon == "" || IncomeNon == "Foreign income" )  ? 
<div className="row" >
<div className="col-md-4 mt-2 new">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselectnew10.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                            Foreign Nationals Full Doc                                               
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                            No score or FICO 660
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                            Up to 75% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                          Qualify Foreign National borrowers with overseas income 
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                            Loan amounts up to $3 million
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                             Overseas assets allowed
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              RON allowed
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('Foreign Nationals Full Doc')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div>
</div> : 
BorrowerNon == "high-net-worth-individuals" && (  IncomeNon == "" || IncomeNon == "1Y Full Doc" ||IncomeNon == "2Y Full Doc" )  ?
<div className="row">

  <div className="col-md-4 mt-2 new">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselectnew5.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                            1Y or 2Y Full Doc Non-QM                                                                                                
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                            Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                            Up to 90% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                          A flexible Non-QM solution for Full Doc borrowers 
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Loan amounts up to $4 million
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              DTI up to 55% on owner-occupied 
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Min. 3 months of reserves
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('1Y or 2Y Full Doc Non-QM')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div>
</div> :
BorrowerNon == "high-net-worth-individuals"  && (  IncomeNon == "" || IncomeNon == "1Y Full Doc" ||IncomeNon == "2Y Full Doc" )  ?
<div className="row">
<div className="col-md-4">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselect2.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                              12,24 Month Bank Statement
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                              Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                              Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                            A perfect fit for self-employed borrowers
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Personal, business, or combined bank statement
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              3 months of reserves
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Concierge Service for income calculation
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('12,24 Month Bank Statement')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div>
</div>
: 
BorrowerNon == "high-net-worth-individuals"  && (  IncomeNon == "" || IncomeNon == "12 Bank Statement" ||IncomeNon == "24 Month Bank Statement" )  ? 
<div className="row">
                    <div className="col-md-4">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselect2.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                              12,24 Month Bank Statement
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                              Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                              Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                            A perfect fit for self-employed borrowers
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Personal, business, or combined bank statement
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              3 months of reserves
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Concierge Service for income calculation
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('12,24 Month Bank Statement')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div>
</div> : 
BorrowerNon == "high-net-worth-individuals" && (  IncomeNon == "" || IncomeNon == "TY P&L Only" ||IncomeNon == "2Y P&L Only" )  ? 
<div className="row">

<div className="col-md-4 new">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselectnew4.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                            1Y + 2Y P&L                                                
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                            Min. FICO 660
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                            Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                          The easiest alternative solution for self-employed borrowers
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              P&L reviewed by Licensed CPA, CTEC registered Preparer or IRS Enrolled Tax Agent
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Bank statements not required up to 70% LTV
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Loan amounts up to $2.5 million
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('1Y + 2Y P&L')}>Learn More</button>
                          </div>
                        </div>
                      </div>
    </div>
</div> :
BorrowerNon == "high-net-worth-individuals" && (  IncomeNon == "" || IncomeNon == "Asset Utilization" )  ? 
<div className="row" >

<div className="col-md-4 mt-2 new">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselectnew8.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                            Asset Utilization                                                
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                            Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                            Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                          An ideal solution for borrowers with liquid assets: retirees, investors, and the self-employed  
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                             Qualifying assets / 60 months
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                             Min. 3 months of reserves
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Loan amounts up to $4 million 
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('Asset Utilization')}>Learn More</button>
                          </div>
                        </div>
                      </div>
</div>
</div> : 
BorrowerNon == "high-net-worth-individuals"  && (  IncomeNon == "" || IncomeNon == "WVOE" )  ? 
<div className="row" >

 <div className="col-md-4 mt-2 new">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselectnew7.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                            WVOE                                                
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                            Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                            Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                          A loan solution for salaried borrowers with no tax returns 
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                             Bank statements not required up to 70% LTV
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Min. 3 months of reserves
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              No tax returns required 
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('WVOE')}>Learn More</button>
                          </div>
                        </div>
                      </div>
 </div>
</div> : 
BorrowerNon == "high-net-worth-individuals"  && (  IncomeNon == "" || IncomeNon == "1099" )  ? 
<div className="row" >
<div className="col-md-4 mt-2 new">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselectnew6.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                            1099                                                 
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                            Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                            Up to 85% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                          An ideal loan for sole proprietors or contract individuals 
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                             1099s for the last 1 year
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              1-year history with the same employer 
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Gift funds allowed 
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('1099')}>Learn More</button>
                          </div>
                        </div>
                      </div>
</div>
</div> : 
BorrowerNon == "high-net-worth-individuals"  &&  (  IncomeNon == "" || IncomeNon == "DSCR < 0.75" || IncomeNon == "DSCR >=1.00" || IncomeNon == "DSCR 0.75-0.99" )  ? 
<div className="row" >

<div className="col-md-4">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselect1.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                              DSCR
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                              Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                              Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                            Close DSCR loan with the fastest turn times in the
                            industry
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Credit for DSCR 1.25
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              No income no employment
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Short-term rental is available
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('DSCR')}>Learn More</button>
                          </div>
                        </div>
                      </div>
</div>
<div className="col-md-4 mt-2 new">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselectnew9.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                            Foreign Nationals DSCR                                               
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                            No score or FICO 660
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                            Up to 70% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                          Qualify Foreign Nationals seeking investment properties with minimal documentation and fast closings
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                            Loan amounts up to $3 million
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                             Cash-out allowed
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              DSCR 1 requires min FICO 680 or no FICO
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('Foreign Nationals DSCR')}>Learn More</button>
                          </div>
                        </div>
                      </div>
</div>
</div> : 
BorrowerNon == "high-net-worth-individuals"   && (  IncomeNon == "" || IncomeNon == "Foreign income" )  ? 
<div className="row" >
<div className="col-md-4 mt-2 new">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselectnew10.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                            Foreign Nationals Full Doc                                               
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                            No score or FICO 660
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                            Up to 75% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                          Qualify Foreign National borrowers with overseas income 
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                            Loan amounts up to $3 million
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                             Overseas assets allowed
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              RON allowed
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('Foreign Nationals Full Doc')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div>
</div> : 


















null
}
                  
</Tab>

{/* done */}
                <Tab eventKey="jumbo" title="Jumbo">
                <div className="container" style={{ padding:'10px'}}>
<select style={{width:'240px',padding:"10px",borderRadius:'5px'}} onClick={(e)=>setBorrowerType(e.target.value)}>
                                    <option hidden  value="program-block">Borrower type</option>
                                    <option value="employed">Employed</option>
                                    <option value="self-employed">Self-employed</option>
                                    <option value="investors">Investors</option>
                                    <option value="foreign-investors">Foreign Investors</option>
                                    <option value="high-net-worth-individuals">High Net Worth Individuals</option>
</select>

<select style={{width:'240px',padding:"10px",marginLeft:'10px',borderRadius:'5px'}} onClick={(e)=>setIncomeType(e.target.value)}>
  <option hidden >Income type</option>
  <option value="2Y Full Doc">2Y Full Doc</option>
  <option value="1Y Full Doc">1Y Full Doc</option>
  <option value="24 Month Bank Statement">24 Month Bank Statement</option>
  <option value="12 Bank Statement">12 Bank Statement</option>
  <option value="TY P&L Only">TY P&L Only</option>
  <option value="2Y P&L Only">2Y P&L Only</option>
  <option value="Asset Utilization">Asset Utilization</option>
  <option value="WVOE">WVOE</option>
  <option value="1099">1099</option>
  <option value="DSCR >=1.00">DSCR &gt;=1.00</option>
  <option value="DSCR 0.75-0.99">DSCR 0.75-0.99</option>
  <option value="DSCR < 0.75">DSCR &lt; 0.75</option>
  <option value="Foreign income" >Foreign income</option>

</select>
</div>
{

  (BorrowerType === "" && IncomeType === "") || 
  (BorrowerType === "employed" && (IncomeType === "" || IncomeType === "2Y Full Doc" || IncomeType === "1Y Full Doc")) || 
  (BorrowerType === "investors" && (IncomeType === "" || IncomeType === "2Y Full Doc" || IncomeType === "1Y Full Doc")) || 
  (BorrowerType === "high-net-worth-individuals" && (IncomeType === "" || IncomeType === "2Y Full Doc" || IncomeType === "1Y Full Doc")) ? 
    <>

                  <div className="row">
                    <div className="col-md-4">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselect4.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                              Prime Jumbo
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                              Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                              Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                            A solution for borrowers seeking large home loans
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Loan amounts up to $2.5 million
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              DTI up to 45%
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              No MI required
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('Prime Jumbo')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
  </> : null
}
                </Tab>

{/* done */}
                <Tab eventKey="conventional" title="Conventional">

                <div className="container" style={{ padding:'10px'}}>
<select style={{width:'240px',padding:"10px",borderRadius:'5px'}} onClick={(e)=>setBorrowerConventional(e.target.value)}>
                                    <option hidden  value="program-block">Borrower type</option>
                                    <option value="employed">Employed</option>
                                    <option value="self-employed">Self-employed</option>
                                    <option value="investors">Investors</option>
                                    <option value="foreign-investors">Foreign Investors</option>
                                    <option value="high-net-worth-individuals">High Net Worth Individuals</option>
</select>

<select style={{width:'240px',padding:"10px",marginLeft:'10px',borderRadius:'5px'}} onClick={(e)=>setIncomeConventional(e.target.value)}>
  <option hidden >Income type</option>
  <option value="2Y Full Doc">2Y Full Doc</option>
  <option value="1Y Full Doc">1Y Full Doc</option>
  <option value="24 Month Bank Statement">24 Month Bank Statement</option>
  <option value="12 Bank Statement">12 Bank Statement</option>
  <option value="TY P&L Only">TY P&L Only</option>
  <option value="2Y P&L Only">2Y P&L Only</option>
  <option value="Asset Utilization">Asset Utilization</option>
  <option value="WVOE">WVOE</option>
  <option value="1099">1099</option>
  <option value="DSCR >=1.00">DSCR &gt;=1.00</option>
  <option value="DSCR 0.75-0.99">DSCR 0.75-0.99</option>
  <option value="DSCR < 0.75">DSCR &lt; 0.75</option>
  <option value="Foreign income" >Foreign income</option>

</select>
</div>
{
BorrowerConventional == "" && ( IncomeConventional == "" || IncomeConventional == "2Y Full Doc" || IncomeConventional == "1Y Full Doc" ) ||
BorrowerConventional == "self-employed" && ( IncomeConventional == "" || IncomeConventional == "2Y Full Doc" || IncomeConventional == "1Y Full Doc" ) ||
BorrowerConventional == "employed" && ( IncomeConventional == "" || IncomeConventional == "2Y Full Doc" || IncomeConventional == "1Y Full Doc" ) ?
                  <div className="row">
                    <div className="col-md-4">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselect9.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                              Conventional Standard
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                              Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                              Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                            An affordable financing option with standard
                            requirements
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Loan amounts up to $766,550
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              DTI up to 50%
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Minimum down payment 3%
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('Conventional Standard')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselect10.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                              Fannie Mae HomeReady
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                              Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                              Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                            The perfect fit for borrowers with limited down
                            payment capabilities
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Loan amounts up to $766,550
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              DTI up to 50%
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Primary residence
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('Fannie Mae HomeReady')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselect11.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                              Freddie Mac Home Possible
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                              Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                              Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                            An affordable mortgage option for low- to
                            moderate-income individuals
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Loan amounts up to $766,550
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              DTI up to 45%
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Cancelable MI
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('Freddie Mac Home Possible')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mt-2 new">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselect4.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                            Conventional High Balance
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                            Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                            Up to 97% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                          Qualify homebuyers seeking properties exceeding the standard limits
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Loan amounts up to $1,149,825
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              DTI up to 50%
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                             Primary, secondary homes, and investment
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('Conventional High Balance')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mt-2 new">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselectnew11.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                            Fannie Mae RefiNow 
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                            Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                            Up to 97% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                          A flexible refinance option with simplified underwriting
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Reduced documentation
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              DTI up to 65%
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                             Owner-occupied, 1-unit primary residence
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('Fannie Mae RefiNow')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mt-2 new">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/coselect5.svg')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                            Freddie Mac Refi Possible
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                            Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                            Up to 97% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                          A flexible refinance option with simplified underwriting
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Reduced documentation
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              DTI up to 65%
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                             Owner-occupied, 1-unit primary residence
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('Freddie Mac Refi Possible')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div>






                  </div> : 
BorrowerConventional == "high-net-worth-individuals" && ( IncomeConventional == "" || IncomeConventional == "2Y Full Doc" || IncomeConventional == "1Y Full Doc" )?
<div className="col-md-4 mt-2 new">
<div
  className="card shadow-sm"
  style={{ width: "100%", borderRadius: "10px" }}
>
  <div className="card-body">
    <div className="d-flex">
      <div
        className="program-block__image program-block__image_dscr"
        style={{
          backgroundImage:
            "url('/img/coselect/coselect4.svg')",
        }}
      ></div>
      <h5 className="card-title text-start mt-3 ps-4 text-black">
      Conventional High Balance
      </h5>
    </div>
    <div className="d-flex justify-content-between mt-2">
      <span className="badge bg-light text-dark p-3 border">
      Min. FICO 620
      </span>
      <span className="badge bg-light text-dark p-3 border">
      Up to 97% CLTV
      </span>
    </div>
    <h6 className="card-text mt-3 text-start text-black">
    Qualify homebuyers seeking properties exceeding the standard limits
    </h6>
    <ul className="list-unstyled mt-3">
      <li className="list text-black">
        <MdPlayArrow
          size={20}
          style={{ color: "#0d6efd" }}
          className="me-2"
        />
        Loan amounts up to $1,149,825
      </li>
      <li className="list text-black">
        {" "}
        <MdPlayArrow
          size={20}
          style={{ color: "#0d6efd" }}
          className="me-2"
        />
        DTI up to 50%
      </li>
      <li className="list text-black">
        <MdPlayArrow
          size={20}
          style={{ color: "#0d6efd" }}
          className="me-2"
        />
       Primary, secondary homes, and investment
      </li>
    </ul>
    <div className="d-flex justify-content-start mt-4">
      <button className="learn_more" onClick={()=>handlegoDaynamicPage('Conventional High Balance')}>Learn More</button>
    </div>
  </div>
</div>
</div>
                :   null
}
                </Tab>



{/* done */}
                <Tab eventKey="government" title="Government">
                <div className="container" style={{ padding:'10px'}}>
<select style={{width:'240px',padding:"10px",borderRadius:'5px'}} onClick={(e)=>setBorrowerTypeGovernment(e.target.value)}>
                                    <option hidden  value="program-block">Borrower type</option>
                                    <option value="employed">Employed</option>
                                    <option value="self-employed">Self-employed</option>
                                    <option value="investors">Investors</option>
                                    <option value="foreign-investors">Foreign Investors</option>
                                    <option value="high-net-worth-individuals">High Net Worth Individuals</option>
</select>

<select style={{width:'240px',padding:"10px",marginLeft:'10px',borderRadius:'5px'}} onClick={(e)=>setIncomeTypeGovernment(e.target.value)}>
  <option hidden >Income type</option>
  <option value="2Y Full Doc">2Y Full Doc</option>
  <option value="1Y Full Doc">1Y Full Doc</option>
  <option value="24 Month Bank Statement">24 Month Bank Statement</option>
  <option value="12 Bank Statement">12 Bank Statement</option>
  <option value="TY P&L Only">TY P&L Only</option>
  <option value="2Y P&L Only">2Y P&L Only</option>
  <option value="Asset Utilization">Asset Utilization</option>
  <option value="WVOE">WVOE</option>
  <option value="1099">1099</option>
  <option value="DSCR >=1.00">DSCR &gt;=1.00</option>
  <option value="DSCR 0.75-0.99">DSCR 0.75-0.99</option>
  <option value="DSCR < 0.75">DSCR &lt; 0.75</option>
  <option value="Foreign income" >Foreign income</option>

</select>
</div>

{
 BorrowerTypeGovernment == "" && (IncomeTypeGovernment == "" || IncomeTypeGovernment == "2Y Full Doc" || IncomeTypeGovernment == "1Y Full Doc" ) ||
 BorrowerTypeGovernment == "employed" && (IncomeTypeGovernment == "" || IncomeTypeGovernment == "2Y Full Doc" || IncomeTypeGovernment == "1Y Full Doc" ) ||
 BorrowerTypeGovernment == "self-employed" && (IncomeTypeGovernment == "" || IncomeTypeGovernment == "2Y Full Doc" || IncomeTypeGovernment == "1Y Full Doc"   ) ?  
  <div className="row">

  <div className="col-md-4">
      <div
        className="card shadow-sm"
        style={{ width: "100%", borderRadius: "10px" }}
      >
        <div className="card-body">
          <div className="d-flex">
            <div
              className="program-block__image program-block__image_dscr"
              style={{
                backgroundImage:
                  "url('/img/coselect/coselect13.svg')",
              }}
            ></div>
            <h5 className="card-title text-start mt-3 ps-4 text-black">
            VA Standard 
            </h5>
          </div>
          <div className="d-flex justify-content-between mt-2">
            <span className="badge bg-light text-dark p-3 border">
            No Min FICO
            </span>
            <span className="badge bg-light text-dark p-3 border">
            Up to 100% CLTV 
            </span>
          </div>
          <h6 className="card-text mt-3 text-start text-black">
          A loan solution for veterans, active-duty service members, and their eligible spouses
          </h6>
          <ul className="list-unstyled mt-3">
            <li className="list text-black">
              <MdPlayArrow
                size={20}
                style={{ color: "#0d6efd" }}
                className="me-2"
              />
              Loan amounts up to $2 million 
            </li>
            <li className="list text-black">
              {" "}
              <MdPlayArrow
                size={20}
                style={{ color: "#0d6efd" }}
                className="me-2"
              />
              Up to 55% DTI 
            </li>
            <li className="list text-black">
              <MdPlayArrow
                size={20}
                style={{ color: "#0d6efd" }}
                className="me-2"
              />
              Up to 100% LTV 
            </li>
          </ul>
          <div className="d-flex justify-content-start mt-4">
            <button className="learn_more" onClick={()=>handlegoDaynamicPage('VA Standard')}>Learn More</button>
          </div>
        </div>
      </div>
  </div>

    <div className="col-md-4">
      <div
        className="card shadow-sm"
        style={{ width: "100%", borderRadius: "10px" }}
      >
        <div className="card-body">
          <div className="d-flex">
            <div
              className="program-block__image program-block__image_dscr"
              style={{
                backgroundImage:
                  "url('/img/coselect/coselect12.svg')",
              }}
            ></div>
            <h5 className="card-title text-start mt-3 ps-4 text-black">
            VA IRRRL 
            </h5>
          </div>
          <div className="d-flex justify-content-between mt-2">
            <span className="badge bg-light text-dark p-3 border">
            Min. FICO 620
            </span>
            <span className="badge bg-light text-dark p-3 border">
            Up to 100% CLTV 
            </span>
          </div>
          <h6 className="card-text mt-3 text-start text-black">
          A streamlined refinance option for veterans and active-duty service members 
          </h6>
          <ul className="list-unstyled mt-3">
            <li className="list text-black">
              <MdPlayArrow
                size={20}
                style={{ color: "#0d6efd" }}
                className="me-2"
              />
              Loan amounts up to $2 million 
            </li>
            <li className="list text-black">
              {" "}
              <MdPlayArrow
                size={20}
                style={{ color: "#0d6efd" }}
                className="me-2"
              />
              Up to 55% DTI 
            </li>
            <li className="list text-black">
              <MdPlayArrow
                size={20}
                style={{ color: "#0d6efd" }}
                className="me-2"
              />
              Primary residence, Investments, Second Home
            </li>
          </ul>
          <div className="d-flex justify-content-start mt-4">
            <button className="learn_more" onClick={()=>handlegoDaynamicPage('VA IRRRL')}>Learn More</button>
          </div>
        </div>
      </div>
    </div>

    <div className="col-md-4 mt-2">
      <div
        className="card shadow-sm"
        style={{ width: "100%", borderRadius: "10px" }}
      >
        <div className="card-body">
          <div className="d-flex">
            <div
              className="program-block__image program-block__image_dscr"
              style={{
                backgroundImage:
                  "url('/img/coselect/coselect5.svg')",
              }}
            ></div>
            <h5 className="card-title text-start mt-3 ps-4 text-black">
              FHA Standard
            </h5>
          </div>
          <div className="d-flex justify-content-between mt-2">
            <span className="badge bg-light text-dark p-3 border">
              Min. FICO 620
            </span>
            <span className="badge bg-light text-dark p-3 border">
              Up to 80% CLTV
            </span>
          </div>
          <h6 className="card-text mt-3 text-start text-black">
            Qualify homebuyers with limited down payment
            capabilities
          </h6>
          <ul className="list-unstyled mt-3">
            <li className="list text-black">
              <MdPlayArrow
                size={20}
                style={{ color: "#0d6efd" }}
                className="me-2"
              />
              Loan amounts up to $498,257
            </li>
            <li className="list text-black">
              {" "}
              <MdPlayArrow
                size={20}
                style={{ color: "#0d6efd" }}
                className="me-2"
              />
              DTI up to 55%
            </li>
            <li className="list text-black">
              <MdPlayArrow
                size={20}
                style={{ color: "#0d6efd" }}
                className="me-2"
              />
              LTV up to 97.75%
            </li>
          </ul>
          <div className="d-flex justify-content-start mt-4">
            <button className="learn_more" onClick={()=>handlegoDaynamicPage('FHA Standard')}>Learn More</button>
          </div>
        </div>
      </div>
    </div>

    <div className="col-md-4 mt-2">
      <div
        className="card shadow-sm"
        style={{ width: "100%", borderRadius: "10px" }}
      >
        <div className="card-body">
          <div className="d-flex">
            <div
              className="program-block__image program-block__image_dscr"
              style={{
                backgroundImage:
                  "url('/img/coselect/coselect6.svg')",
              }}
            ></div>
            <h5 className="card-title text-start mt-3 ps-4 text-black">
              FHA High Balance
            </h5>
          </div>
          <div className="d-flex justify-content-between mt-2">
            <span className="badge bg-light text-dark p-3 border">
              Min. FICO 620
            </span>
            <span className="badge bg-light text-dark p-3 border">
              Up to 80% CLTV
            </span>
          </div>
          <h6 className="card-text mt-3 text-start text-black">
            A mortgage option allowing for larger loan amounts
            in high-cost areas
          </h6>
          <ul className="list-unstyled mt-3">
            <li className="list text-black">
              <MdPlayArrow
                size={20}
                style={{ color: "#0d6efd" }}
                className="me-2"
              />
              Loan amounts up to $1,149,285
            </li>
            <li className="list text-black">
              {" "}
              <MdPlayArrow
                size={20}
                style={{ color: "#0d6efd" }}
                className="me-2"
              />
              DTI up to 55%
            </li>
            <li className="list text-black">
              <MdPlayArrow
                size={20}
                style={{ color: "#0d6efd" }}
                className="me-2"
              />
              Primary residence, second home
            </li>
          </ul>
          <div className="d-flex justify-content-start mt-4">
            <button className="learn_more" onClick={()=>handlegoDaynamicPage('FHA High Balance')}>Learn More</button>
          </div>
        </div>
      </div>
    </div>

    <div className="col-md-4 mt-2">
      <div
        className="card shadow-sm"
        style={{ width: "100%", borderRadius: "10px" }}
      >
        <div className="card-body">
          <div className="d-flex">
            <div
              className="program-block__image program-block__image_dscr"
              style={{
                backgroundImage:
                  "url('/img/coselect/coselect7.svg')",
              }}
            ></div>
            <h5 className="card-title text-start mt-3 ps-4 text-black">
              FHA Streamline Refinance
            </h5>
          </div>
          <div className="d-flex justify-content-between mt-2">
            <span className="badge bg-light text-dark p-3 border">
              Min. FICO 620
            </span>
            <span className="badge bg-light text-dark p-3 border">
              Up to 80% CLTV
            </span>
          </div>
          <h6 className="card-text mt-3 text-start text-black">
            Current FHA loan refinance solution with minimal
            requirements
          </h6>
          <ul className="list-unstyled mt-3">
            <li className="list text-black">
              <MdPlayArrow
                size={20}
                style={{ color: "#0d6efd" }}
                className="me-2"
              />
              Min. 6 payments on current FHA loan
            </li>
            <li className="list text-black">
              {" "}
              <MdPlayArrow
                size={20}
                style={{ color: "#0d6efd" }}
                className="me-2"
              />
              Primary residence
            </li>
            <li className="list text-black">
              <MdPlayArrow
                size={20}
                style={{ color: "#0d6efd" }}
                className="me-2"
              />
              No appraisal for stick-built properties
            </li>
          </ul>
          <div className="d-flex justify-content-start mt-4">
            <button className="learn_more" onClick={()=>handlegoDaynamicPage('FHA Streamline Refinance')}>Learn More</button>
          </div>
        </div>
      </div>
    </div>



 

  </div> : BorrowerTypeGovernment == "high-net-worth-individuals" && (IncomeTypeGovernment == "" || IncomeTypeGovernment == "2Y Full Doc" || IncomeTypeGovernment == "1Y Full Doc" ) ?  
  <div className="col-md-4 mt-2">
      <div
        className="card shadow-sm"
        style={{ width: "100%", borderRadius: "10px" }}
      >
        <div className="card-body">
          <div className="d-flex">
            <div
              className="program-block__image program-block__image_dscr"
              style={{
                backgroundImage:
                  "url('/img/coselect/coselect6.svg')",
              }}
            ></div>
            <h5 className="card-title text-start mt-3 ps-4 text-black">
              FHA High Balance
            </h5>
          </div>
          <div className="d-flex justify-content-between mt-2">
            <span className="badge bg-light text-dark p-3 border">
              Min. FICO 620
            </span>
            <span className="badge bg-light text-dark p-3 border">
              Up to 80% CLTV
            </span>
          </div>
          <h6 className="card-text mt-3 text-start text-black">
            A mortgage option allowing for larger loan amounts
            in high-cost areas
          </h6>
          <ul className="list-unstyled mt-3">
            <li className="list text-black">
              <MdPlayArrow
                size={20}
                style={{ color: "#0d6efd" }}
                className="me-2"
              />
              Loan amounts up to $1,149,285
            </li>
            <li className="list text-black">
              {" "}
              <MdPlayArrow
                size={20}
                style={{ color: "#0d6efd" }}
                className="me-2"
              />
              DTI up to 55%
            </li>
            <li className="list text-black">
              <MdPlayArrow
                size={20}
                style={{ color: "#0d6efd" }}
                className="me-2"
              />
              Primary residence, second home
            </li>
          </ul>
          <div className="d-flex justify-content-start mt-4">
            <button className="learn_more" onClick={()=>handlegoDaynamicPage('FHA High Balance')}>Learn More</button>
          </div>
        </div>
      </div>
    </div> : null
  
}
                  
                </Tab>


{/* done */}
                <Tab eventKey="second_lien" title="Second Lien">
<div className="container" style={{ padding:'10px'}}>
<select style={{width:'240px',padding:"10px",borderRadius:'5px'}} onClick={(e)=>setBorrowerTypeSecondLien(e.target.value)}>
                                    <option hidden  value="program-block">Borrower type</option>
                                    <option value="employed">Employed</option>
                                    <option value="self-employed">Self-employed</option>
                                    <option value="investors">Investors</option>
                                    <option value="foreign-investors">Foreign Investors</option>
                                    <option value="high-net-worth-individuals">High Net Worth Individuals</option>
</select>

<select style={{width:'240px',padding:"10px",marginLeft:'10px',borderRadius:'5px'}} onClick={(e)=>setIncomeTypeSecondLien(e.target.value)}>
  <option hidden >Income type</option>
  <option value="2Y Full Doc">2Y Full Doc</option>
  <option value="1Y Full Doc">1Y Full Doc</option>
  <option value="24 Month Bank Statement">24 Month Bank Statement</option>
  <option value="12 Bank Statement">12 Bank Statement</option>
  <option value="TY P&L Only">TY P&L Only</option>
  <option value="2Y P&L Only">2Y P&L Only</option>
  <option value="Asset Utilization">Asset Utilization</option>
  <option value="WVOE">WVOE</option>
  <option value="1099">1099</option>
  <option value="DSCR >=1.00">DSCR &gt;=1.00</option>
  <option value="DSCR 0.75-0.99">DSCR 0.75-0.99</option>
  <option value="DSCR < 0.75">DSCR &lt; 0.75</option>
  <option value="Foreign income" >Foreign income</option>

</select>
</div>
{
 IncomeTypeSecondLien == "DSCR 0.75-0.99" || IncomeTypeSecondLien == "DSCR < 0.75" ? null : <div className="row">
                    <div className="col-md-4">
                      <div
                        className="card shadow-sm"
                        style={{ width: "100%", borderRadius: "10px" }}
                      >
                        <div className="card-body">
                          <div className="d-flex">
                            <div
                              className="program-block__image program-block__image_dscr"
                              style={{
                                backgroundImage:
                                  "url('/img/coselect/last.png')",
                              }}
                            ></div>
                            <h5 className="card-title text-start mt-3 ps-4 text-black">
                              Second Mortgage
                            </h5>
                          </div>
                          <div className="d-flex justify-content-between mt-2">
                            <span className="badge bg-light text-dark p-3 border">
                              Min. FICO 620
                            </span>
                            <span className="badge bg-light text-dark p-3 border">
                              Up to 80% CLTV
                            </span>
                          </div>
                          <h6 className="card-text mt-3 text-start text-black">
                            A solution to secure additional funding with
                            existing equity
                          </h6>
                          <ul className="list-unstyled mt-3">
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Loan amounts up to $500,000
                            </li>
                            <li className="list text-black">
                              {" "}
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              DTI up to 50%
                            </li>
                            <li className="list text-black">
                              <MdPlayArrow
                                size={20}
                                style={{ color: "#0d6efd" }}
                                className="me-2"
                              />
                              Owner-occupied, second home, or investment
                            </li>
                          </ul>
                          <div className="d-flex justify-content-start mt-4">
                            <button className="learn_more" onClick={()=>handlegoDaynamicPage('Second Mortgage')}>Learn More</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
}
                </Tab>


              </Tabs>
            </div>
            {/* <div className="container">
              <div className="row ms-1 mt-2">
                <button className="learn_more_program">All Program</button>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CoSelect;
