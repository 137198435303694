export const mockDataCoselect = [
    {
      heading: 'DSCR',
      subtitle1: 'Min. FICO 620',
      subtitle2: 'Up to 80% CLTV',
      details: 'Our DSCR loan program is specifically designed to assist new and experienced real estate investors in financing their properties, qualifying based on the cash flow generated by the investment.',
      programFuture: [
        'DSCR as low as 0',
        '1007 only for rental income',
        'Credit for DSCR >1.25',
        'Min. 3 months of reserves required',
        'Cash-out available up to 75% LTV',
        'Gift funds allowed'
      ],
      whyChoose: [
        'No income and no employment needed',
        'No ratio loans — borrowers do not have to worry about the debt service coverage ratio',
        'Fastest turn times in the industry'
      ]
    },
    {
      heading: 'Bank Statement Loan',
      subtitle1: 'Min. FICO 640',
      subtitle2: 'Up to 90% LTV',
      details: 'Our Bank Statement Loan program is perfect for self-employed borrowers who can use their bank statements as proof of income.',
      programFuture: [
        'No tax returns required',
        '12 or 24 months of personal or business bank statements',
        'Cash-out options available',
        'Gift funds allowed'
      ],
      whyChoose: [
        'Great for self-employed borrowers',
        'Flexible income qualification',
        'Higher LTV options compared to traditional loans'
      ]
    },
    {
      heading: '12,24 Month Bank Statement',
      subtitle1: 'Min. FICO 620',
      subtitle2: 'Up to 90% CLTV',
      details: 'Our 12,24 Month Bank Statement loans are perfect Non-QM options for the self-employed. Even if complex tax deductions make their tax returns less than straightforward, this program can provide a clearer picture of their current income or revenue to help them qualify.',
      programFuture: [
        'Loan amount up to $4 million',
        'DTI up to 55%',
        'Min. 3 months of reserves required',
        'Max cash-in-hand $500,000 for CLTV >65%, $1,000,000 for CLTV ≤65% to ≥55%, no max cash-in-hand limitation for CLTV <55%',
        'Mortgage history 0 x 60 x 12, 0 x 30 x 12, 0 x 90 x 24'
      ],
      whyChoose: [
        'Free service to calculate income',
        'Combined statements accepted',
        'No tax returns required'
      ]
    },
    {
      heading: 'ITIN',
      subtitle1: 'Min. FICO 660',
      subtitle2: 'Up to 80% CLTV',
      details: 'Our ITIN mortgage loan program is a mortgage solution for individuals who have an Individual Taxpayer Identification Number but do not have a Social Security Number. With this program, you can grow your business by making homeownership a reality for non-U.S. citizens.',
      programFuture: [
        'Min FICO 660 and up to 80% CLTV for Super Prime',
        'Min FICO 700 and up to 70% CLTV for DSCR',
        'Loan amounts up to $1.5 million',
        'A valid ITIN card or IRS ITIN letter and an unexpired government photo ID required',
        'As little as 3 months of reserves'
      ],
      whyChoose: [
        'Primary residence & investment allowed',
        'DSCR allowed',
        'Alt docs allowed'
      ]
    },
    {
        heading: '1Y + 2Y P&L',
        subtitle1: 'Min. FICO 660',
        subtitle2: 'Up to 80% CLTV',
        details: 'Our Non-QM 1-year and 2-year P&L loans are designed for self-employed individuals. These loans allow borrowers to use their business\'s Profit & Loss (P&L) statements to qualify, bypassing traditional income verification.',
        programFuture: [
          'P&L reviewed by Licensed CPA, CTEC registered Preparer or IRS Enrolled Tax Agent',
          'Loan amounts up to $2.5 million',
          'Max cash-in-hand $500,000 for CLTV >65%, $1,000,000 for CLTV ≤65% to ≥55%, no max cash-in-hand limitation for CLTV <55%',
          'DTI up to 55%',
          'Temporary rate buydowns available'
        ],
        whyChoose: [
          'Bank statements not required up to 70% LTV',
          'Income support tolerance 25%',
          '1-year business existence may be considered'
        ]
    },
    {
        heading: '1Y or 2Y Full Doc Non-QM',
        subtitle1: 'Min. FICO 620',
        subtitle2: 'Up to 90% CLTV',
        details: 'Our Full Doc Non-QM loans are a perfect solution for small business owners, investors, or other self-employed individuals who believe they may not qualify for a traditional agency loan.',
        programFuture: [
          'Loan amounts up to $4 million',
          'DTI up to 55%',
          'Max cash-in-hand $500,000 for CLTV >65%, $1,000,000 for CLTV ≤65% to ≥55%, no max cash-in-hand limitation for CLTV <55%',
          'Condos up to 90% CLTV, condotels up to 75% CLTV, NY up to 90% CLTV',
          '30 & 40-year fixed'
        ],
        whyChoose: [
          'ITIN allowed',
          '1 year credit event allowed',
          'Temporary rate buydowns available'
        ]
    },
    {
        heading: '1099',
        subtitle1: 'Min. FICO 620',
        subtitle2: 'Up to 85% CLTV',
        details: 'Our 1099 loan is a solution for self-employed and contract borrowers that allows them to use their 1099 forms to report various types of non-employment income received during the tax year.',
        programFuture: [
          'Loan amounts up to $4 million',
          'DTI up to 55%',
          'Cash out available',
          '1-year history with the same employer',
          'Temporary rate buydowns available'
        ],
        whyChoose: [
          '1099s for the last 1 year allowed',
          'Expense ratio 10%',
          'Gift funds allowed'
        ]
    },
    {
        heading: 'WVOE',
        subtitle1: 'Min. FICO 620',
        subtitle2: 'Up to 80% CLTV',
        details: 'The WVOE program is a unique category of mortgage solutions that differs from Conventional or Government financing options. This program verifies income through a Written Employer Verification (WVOE) form completed by a borrower\'s current employer, rather than relying on tax returns. The program provides innovative solutions for borrowers who lack traditional tax returns or W-2s, have complex financial profiles, or have difficulty qualifying for Conventional mortgages.',
        programFuture: [
          'Loan amounts up to $4 million',
          'DTI up to 55%',
          'Cash out available',
          'Completed FNMA Form 1005 for a 2-year history with the same employer',
          'Paystubs, tax returns, 4506-C, or W-2\'s not required'
        ],
        whyChoose: [
          'Bank statements must reflect deposits from the employer supporting at least 75% of gross income',
          'Bank statements not required up to 70% LTV',
          'Gift funds allowed'
        ]
    },
    {
        heading: 'Asset Utilization',
        subtitle1: 'Min. FICO 620',
        subtitle2: 'Up to 80% CLTV',
        details: 'Our Asset Utilization mortgage program is designed for those who may not have easily quantifiable sources of income, such as the self-employed, entrepreneurs, retirees, and those living off their investments. This program allows them to use their personal assets as a means to qualify for a home mortgage, providing flexibility in obtaining financing.',
        programFuture: [
          'Loan amounts up to $4 million',
          'DTI up to 55%',
          'Cash-out up to 80% LTV',
          'Qualifying assets / 60 months',
          '100% of checking, savings, stocks, bonds, and 70% of retirement assets'
        ],
        whyChoose: [
          'Min. 3 months of reserves required',
          'Gift funds allowed',
          '4 months of seasoning'
        ]
    },
    {
        heading: 'Foreign Nationals DSCR',
        subtitle1: 'No score or FICO 660',
        subtitle2: 'Up to 70% CLTV',
        details: 'Our Foreign National DSCR program is designed for individuals living abroad who are interested in purchasing or refinancing investment property in the US. This is a DSCR program, meaning a borrower qualifies based on the cash flow generated by the investment, no income is required.',
        programFuture: [
          'Loan amounts up to $3 million',
          'Overseas assets allowed as reserves',
          'Cash-out allowed',
          'One bank reference letter',
          'Min reserves 12 months'
        ],
        whyChoose: [
          'DSCR < 1 requires min FICO 680 or no FICO',
          'Gift funds allowed',
          'RON closing'
        ]
    },
    {
        heading: 'Foreign Nationals Full Doc',
        subtitle1: 'No score or FICO 660',
        subtitle2: 'Up to 75% CLTV',
        details: 'Our Foreign National Full Doc program is designed for individuals living abroad who are interested in purchasing or refinancing investment property in the US. This program features a flexible and efficient alternative documentation process and realistic guidelines, which makes it easier for foreign borrowers to qualify for a loan and ensures a smooth and hassle-free experience.',
        programFuture: [
          'Loan amounts up to $3 million',
          'Overseas assets allowed as reserves',
          'Cash-out allowed',
          'One bank reference letter',
          'CPA Letter for the last 2 years & year-to-date'
        ],
        whyChoose: [
          'Homeownership outside the US is allowed',
          'Gift funds allowed',
          'RON closing'
        ]
    },
    {
        heading: 'Prime Jumbo',
        subtitle1: 'Min. FICO 680',
        subtitle2: 'Up to 80% CLTV',
        details: 'A&D Mortgage\'s Prime Jumbo loan is a financing option for homebuyers looking to purchase luxury properties and homes that fall outside of standard loan guidelines. It\'s a solution for those who cannot qualify for an agency loan due to the high price of their home.',
        programFuture: [
          'Loan amounts up to $2.5 million',
          'DTI up to 45%',
          'No MI required',
          'Cash-out up to 75% HCLTV',
          'Income as per DU'
        ],
        whyChoose: [
          'No overlays for credit and tradelines – per DU',
          'Delayed financing is available for up to 180 days',
          'Gift funds allowed'
        ]
    },
    {
        heading: 'Second Mortgage',
        subtitle1: 'Min. FICO 680',
        subtitle2: 'Up to 85% CLTV',
        details: 'Our Second Mortgage is designed to provide an additional financing option for homeowners who are looking to borrow money against the equity in their homes. A Second Mortgage can be used for a variety of purposes, including home renovations, debt consolidation, or other expenses. With this program, you can help your clients unlock the financial potential of their homes while expanding your lending offerings.',
        programFuture: [
          'Max loan amounts up to $500,000',
          'Min loan amounts $50,000',
          'DTI up to 50%',
          '30-year fixed term',
          'Gift funds allowed'
        ],
        whyChoose: [
          'No FICO option available',
          'Alt docs allowed',
          'Eligible for Non-Permanent Resident & Foreign National (Investment only)'
        ]
    },
    {
        heading: 'Conventional Standard',
        subtitle1: 'Min. FICO 620',
        subtitle2: 'Up to 97% CLTV',
        details: 'Conventional loans are fully compliant with Fannie Mae and Freddie Mac guidelines. A Conventional Standard program is a traditional mortgage option with competitive rates and flexible down payments starting at 3%. The program is designed for creditworthy homebuyers looking for a straightforward path to homeownership.',
        programFuture: [
          'Loan amounts up to $802,650',
          'FICO 620',
          'Up to 97% LTV',
          'DTI up to 50%',
          'Min down payment 3%'
        ],
        whyChoose: [
          'Temporary rate buydowns available',
          'No overlays with FNMA/Freddie',
          'Cancelable MI'
        ]
    },
    {
        heading: 'Fannie Mae HomeReady',
        subtitle1: 'Min. FICO 620',
        subtitle2: 'Up to 97% CLTV',
        details: 'Fannie Mae\'s HomeReady mortgage is a lifeline for creditworthy, low- and moderate-income borrowers seeking to purchase or refinance a home. It offers the security of a Conventional loan with potential cost savings on mortgage insurance. Qualifying for a HomeReady mortgage can be easier thanks to a lower down payment option and flexible sources of down payment funds.',
        programFuture: [
          'FICO 620',
          'Loan amounts up to $802,650',
          'DTI up to 50%',
          'Primary residence',
          'Homebuyer education is required for first-time buyers'
        ],
        whyChoose: [
          'Temporary rate buydowns available',
          'Gift funds allowed',
          'Cancelable MI'
        ]
    },
    {
        heading: 'Freddie Mac Home Possible',
        subtitle1: 'Min. FICO 620',
        subtitle2: 'Up to 97% CLTV',
        details: 'Freddie Mac Home Possible is a mortgage program designed to help low- and moderate-income buyers achieve homeownership. It offers low down payment financing with discounted fees and flexible loan options, making it easier to qualify for a mortgage.',
        programFuture: [
          'Loan amounts up to $802,650',
          'DTI up to 50%',
          'Up to 80% of the AMI',
          'Homebuyer education is required for first-time buyers',
          '1-4 units, condos, co-ops, and PUDs; manufactured homes with restrictions'
        ],
        whyChoose: [
          'Temporary rate buydowns available',
          'Cancelable MI',
          'Certain credit fees are capped'
        ]
    },
    {
        heading: 'Conventional High Balance',
        subtitle1: 'Min. FICO 620',
        subtitle2: 'Up to 97% CLTV',
        details: 'A Conventional High Balance loan is a mortgage option for borrowers who need financing for an amount that exceeds the national baseline conforming loan limits but stays within the local conforming loan limits for the high-cost county. This allows homebuyers to borrow more money to purchase a home in a more expensive area.',
        programFuture: [
          'Loan amounts up to $1,203,975',
          'FICO 620',
          'Up to 97% LTV',
          'Cash-out allowed',
          'Primary, secondary homes, and investment'
        ],
        whyChoose: [
          'Temporary rate buydowns available',
          'No overlays with FNMA/Freddie',
          'Faster turn times'
        ]
    },
    {
        heading: 'Fannie Mae RefiNow',
        subtitle1: 'Min. FICO 620',
        subtitle2: 'Up to 97% CLTV',
        details: 'The Fannie Mae RefiNow program is a refinance option for lower-income homeowners with Fannie Mae-held mortgages. It aims to make refinancing easier and more affordable by simplifying the process and offering potential interest rate reductions.',
        programFuture: [
          'FICO 620',
          'Up to 97% LTV',
          'DTI up to 65%',
          'Min 12-month seasoning requirement',
          '1-unit primary residence'
        ],
        whyChoose: [
          'Reduced documentation requirements',
          '$500 credit will be provided if an appraisal has been obtained for the transaction',
          'Income at or below 100% of the Area Median Income'
        ]
    },
    {
        heading: 'Freddie Mac Refi Possible',
        subtitle1: 'Min. FICO 620',
        subtitle2: 'Up to 97% CLTV',
        details: 'The Freddie Mac Refi Possible program is designed to help low- and moderate-income homeowners refinance their mortgages to lower their monthly payments. It offers flexible options for those who may not qualify for traditional refinance programs.',
        programFuture: [
          'FICO 620',
          'Up to 97% LTV',
          'DTI up to 65%',
          'Min 12-month seasoning requirement',
          '1-unit primary residence'
        ],
        whyChoose: [
          'Reduced documentation requirements',
          '$500 credit will be provided if an appraisal has been obtained for the transaction',
          'Income at or below 100% of the Area Median Income'
        ]
    },
    {
        heading: 'VA Standard',
        subtitle1: 'No Min FICO',
        subtitle2: 'Up to 100% CLTV',
        details: 'The VA Standard loan is a mortgage program offered by the Department of Veterans Affairs (VA) to veterans, active-duty service members, and their eligible spouses. It\'s designed to help veterans and their families buy, build, repair, maintain, or adapt a home for personal use.',
        programFuture: [
          'Loan amounts up to $2 million',
          'No Min FICO',
          'Up to 100% LTV',
          'No private mortgage insurance (PMI)',
          'No down payment required'
        ],
        whyChoose: [
          'Limited closing costs',
          'Temporary rate buydowns are available',
          'No overlays on DTI'
        ]
    },
    {
        heading: 'VA IRRRL',
        subtitle1: 'No min. FICO',
        subtitle2: 'Up to 100% CLTV',
        details: 'The VA Interest Rate Reduction Refinance Loan (IRRRL) is a streamlined refinance option for veterans and active-duty service members with existing VA loans. This solution allows borrowers to lower their interest rate and monthly mortgage payments with minimal paperwork and underwriting requirements.',
        programFeature: [
          'Loan amounts up to $2 million',
          'FICO 620',
          'Up to 100% LTV',
          'DTI up to 55%',
          'High balance allowed'
        ],
        whyChoose: [
          '$500 cashback as a result of incidental changes at closing',
          'Only ownership certification required',
          'Appraisal is not required'
        ]
    },
    {
        heading: 'FHA Standard',
        subtitle1: 'Min. FICO 620',
        subtitle2: 'Up to 97.75% CLTV',
        details: 'FHA Standard loans are government-backed mortgages designed to help low- to moderate-income borrowers achieve homeownership. With less stringent credit score and down payment requirements than traditional loans, FHA loans are ideal for first-time homebuyers.',
        programFuture: [
          'Loan amounts up to $498,257*',
          'DTI up to 55%',
          'LTV up to 97.75%',
          'Min. FICO 620',
          'MIP is required'
        ],
        whyChoose: [
          'Great for first-time homebuyers',
          'Min. down payment 2.25%',
          'Less than average credit score requirements'
        ]
    },
    {
        heading: 'FHA High Balance',
        subtitle1: 'Min. FICO 620',
        subtitle2: 'Up to 97.75% CLTV',
        details: 'FHA High Balance loans are designed for those looking to purchase a home in an expensive area. The Federal Housing Finance Agency (FHFA) increases loan limits in these locations, allowing homebuyers to borrow more for their dream home.',
        programFuture: [
          'Loan amounts up to $1,149,825*',
          'DTI up to 55%',
          'Primary residence, second home',
          'Up to 97.75% CLTV',
          'Min. FICO 620'
        ],
        whyChoose: [
          'Min. down payment 2.25%',
          'Flexible qualification requirements',
          'Accessible to borrowers without perfect credit score & history'
        ]
    },
    {
        heading: 'FHA Streamline Refinance',
        subtitle1: 'Min. FICO 640',
        details: 'The FHA Streamline Refinance program is designed to help borrowers who have a current FHA-insured loan. It\'s an easy way to potentially lower interest rates and reduce monthly payments. This refinance option requires minimal paperwork, making the process faster and easier.',
        programFuture: [
          'Available to borrowers with an existing FHA loan',
          'No income is required',
          'No appraisal documentation',
          'No need to calculate a DTI',
          'Permanently lowered monthly payments'
        ],
        whyChoose: [
          'Min. 6 payments on current loan',
          'Reduced paperwork',
          'Simple eligibility requirements'
        ]
    }
  ];