import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { mockDataCoselect } from './CoSelectMockData';

const CoSelectDaynamic = () => {

  const { id } = useParams(); 
  console.log(id, 'id from params');
const [filterData , setFilterData] = useState([])
useEffect(() => {
  const filteredData = mockDataCoselect?.filter((e) => e.heading === id);
  setFilterData(filteredData);
}, [id]);
  console.log(filterData,'filterData');
  
  if(filterData?.length <= 0){
return <h1>loading</h1>
  }

  return (
    <div className='container'>

        <div style={{display:'flex',justifyContent:'center',alignItems:'center',marginTop:'20px'}}>

 
<div className="col-md-6" > 
<h1 style={{fontWeight:'bolder',fontSize:'60px'}}>{id}</h1>

<div style={{display:'flex' ,justifyContent:'space-between',width:'50%'}}>

  <div style={{borderRadius:'8px' ,border:'1px solid black' , width:'120px',textAlign:'center', padding:'5px'}}>
  {filterData[0]?.subtitle1}
  </div>

  <div style={{borderRadius:'8px' ,border:'1px solid black' , width:'120px',textAlign:'center', padding:'5px'}}>
  {filterData[0]?.subtitle2}
  </div>
</div>

<div style={{marginTop:'10px'}}>
  <p>
{filterData[0]?.details}
  </p>

  <div>
   <p style={{fontWeight:'bolder'}}>
   Program features
    </p>
    <div>
      <ul>
        {filterData[0]?.programFuture?.map((e)=>{
          return <li>{e}</li>
        })}
      </ul>
    </div> 
  </div>

  <div>
   <p style={{fontWeight:'bolder'}}>
   Why choose our {filterData[0]?.heading}
    </p>
    <div>
      <ul>
      {filterData[0]?.whyChoose?.map((e)=>{
         return <li>{e}</li>
        })}
       
      </ul>
    </div> 
  </div>

  <div style={{display:'flex',alignItems:'center'}}>
    <div>
      <img src="/img/coselectdaynamic/download-file.svg" alt="img" />
    </div>
    <div style={{marginLeft : "10px"}}>
      <p style={{fontWeight:'bolder',fontSize:'20px'}}>
      Download program PDF
      </p>
      <button className='btn btn-primary rounded'>Download</button>
      </div>
  </div>
</div>



</div>

<div className='col-md-6' style={{padding:'10px'}}>

              <div style={{backgroundColor:'#F3EEF8',padding:'15px',borderRadius:'10px' ,display:'flex',justifyContent:'center'}} >
                <div style={{width:'90%'}}>

<h1 style={{textAlign:''}}>Program request</h1>
<p>Write to us, we will contact you within 30 minutes.</p>


<div style={{margin:'auto'}}>
  <div style={{marginTop:'10px'}}>
<label htmlFor="" style={{fontWeight:'bolder'}}>Name*</label>
                <div>
                <input type="text" placeholder="Enter your name" className='form-control text-capitalize 123 mt-2 mb-2 ps-1'/>
                </div>
  </div>


  <div style={{marginTop:'10px'}}>
<label htmlFor="" style={{fontWeight:'bolder'}}>Phone Number*</label>
                <div>
                <input type="number" placeholder="Enter your phone number" className='form-control text-capitalize 123 mt-2 mb-2 ps-1'/>
                </div>
  </div>

  <div style={{marginTop:'10px'}}>
<label htmlFor="" style={{fontWeight:'bolder'}}>Email*</label>
                <div>
                <input type="text" name="email" placeholder="Enter your email" className='form-control text-capitalize 123 mt-2 mb-2 ps-1'/>
                </div>
  </div>

  <div style={{marginTop:'10px'}}>
<label htmlFor="" style={{fontWeight:'bolder'}}>Inquire*</label>
                <div>
                <textarea type="text" name="inquire" placeholder="Enter your inquire" className='form-control text-capitalize 123 mt-2 mb-2 ps-1'/>
                </div>
  </div>
  <button className="btn btn-primary rounded">Send</button>

 </div>

</div>




              </div>

             

            </div>


        </div>

     
    </div>
  )
}

export default CoSelectDaynamic
