import Header from "../Layout/Header"

const Rates = () => {
  return (
    <div>
        <Header />
        <div>
             <h1 style={{textAlign:'center'}}>The Mortgage Calculator Live Mortgage Rates</h1>
             <div style={{textAlign:'center'}}><span style={{fontWeight:'bolder',textAlign:'center'}}>Rates for 10/1/2024</span><span>- LIVE Rate Estimates based on an estimated 80% LTV, 760 FICO, 40% DTI * - For an Official Loan Estimate Please Apply Here: Apply Now</span></div>
        </div>

<div className="row" style={{display:'flex',justifyContent:'center',gap:'10px',marginTop:'10px',width:'100%'}}>


<div className="col-6 col-sm-6 col-md-4 col-lg-2" style={{backgroundColor:'#E8E8E8',borderRadius:'10px',padding:'20px',textAlign:'center'}}>
    <p style={{fontWeight:'bold',fontSize:'18px',width:'150px',textAlign:'center',margin:'auto',marginBottom:'5px'}}>30 Year Fixed
    Conventional Primary</p>
    <p style={{color:'#272265' ,fontWeight:"bolder",fontSize:'17px' ,lineHeight:'10px'}}>RATES AS LOW AS</p>
    <p style={{color:'#272265' ,fontWeight:"bolder",fontSize:'17px',lineHeight:'10px'}}>4.990%</p>
    <p style={{color:'#272265' ,fontWeight:"bolder",fontSize:'17px',lineHeight:'10px'}}>APR: 6.171%</p>

</div>







</div>


    </div>
  )
}

export default Rates